<template>
    <section class="booking-management">
        <div class="form-search-container bg-white ">
            <el-form :model="formSearch" inline size="small" style="margin-left: 20px">
                <el-form-item >
                    <el-input style="width: 400px" v-model="formSearch.keyword" placeholder="请输入预约人姓名/手机号/地址/管家姓名" />
                </el-form-item>
                <el-form-item>
<!--                    <el-input v-model="formSearch.status" placeholder="请输入承租人电话搜索" clearable/>-->
                  <el-select ref="stateRef"  v-model="formSearch.status" size="small" placeholder="请选择处理状态" clearable
                             style="margin-right: 10px; width: 200px">
                    <el-option label="全部" :value="''"/>
                    <el-option label="待管家确认" :value="0"/>
                    <el-option label="管家已确认" :value="1"/>
                    <el-option label="预约已看房" :value="2"/>
                    <el-option label="已签约" :value="4"/>
                  </el-select>
                </el-form-item>
                <el-form-item >

                    <el-button type="primary" @click="handleSearch" style="margin-left: 10px">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-container bg-white">
            <r-e-table ref="communityTableRef" :dataRequest="getCommunityTableData" :columns="tableColumn" :height="795"
                       :query="formSearch" :cell-style="cellStyle">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" v-if="row.status === 0" @click="onHandle(row,1)">确认</span>
                            <span class="table-btn" v-if="row.status === 1" @click="onHandle(row,2)">已看</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/rental-management/booking-management/data";
    import {getBookingListApi, getBookingPutStatus} from "@/api/booking-management";
    import {MessageSuccess, MessageError, MessageInfo, MessageWarning} from "@custom/message";
    export default {
        name: "booking-management",
        data() {
            return {
                tableColumn: tableColumn(this),
                followStatus: [],
                formSearch: {
                  keyword: "",
                  status: "",
                }
            };
        },
        async created() {
            // 预约跟进状态 600400
            this.followStatus = await this.$store.dispatch("app/getDictionaryByCodeActions", "600400");
        },
        methods: {
          cellStyle({row, column, rowIndex, columnIndex}){
            let {status} = row;
            // if (workOrderStatusName === '待资产部审核' || workOrderStatusName === '待财务部审核' || workOrderStatusName === '待管理员审核') return {color: '#FF0000'};
            if (status === 0  && columnIndex === 7) return {color: '#FF3718'};
            if (status === 1  && columnIndex === 7) return {color: '#FF3718'};
            //  字体颜色
          },
            handleSearch() {
                // 搜索
                this.$refs.communityTableRef.getTableData();
            },
            handleReset() {
                // 重置
                this.formSearch.keyword = "";
                this.formSearch.status = "";
                this.handleSearch();
            },
            getCommunityTableData(params) {
                // 获取预定管理列表
                return getBookingListApi(params);
            },
            onHandle(row, status) {
                let that = this;
                const {uuid} = row;
                const params = {uuid, status};
                const msg = status === 1 ? '此操作将会确认用户预约看房, 是否继续' : '此操作将会把用户的预约状态改为已看房, 是否继续?';
                this.$confirm(`${msg}`, '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                    getBookingPutStatus(params).then((res) => {
                        loading.close();
                        that.handleReset();
                        MessageSuccess('操作成功!');
                    }).catch((err) => loading.close());
                }).catch(() => {
                    this.$message({type: 'info', message: '已取消操作'});
                });
            }
        },

    };
</script>

<style lang="scss" scoped>
    .booking-management {
        padding: 15px;

        .form-search-container {
            padding-top: VH(20px);
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                }
            }
        }
    }
</style>
