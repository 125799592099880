// 房源预约 tableColumn
import {timeFormat} from "@custom/index";

export function tableColumn(_this){
    return [
        {
            type: "index",
            label: "序号",
            width: 60
        },
        {
            prop: "leasorName",
            label: "预约人名字",
            width: 100
        },
        {
            prop: "leasorPhone",
            label: "预约人手机号",
            width: 130,
        },
        {
            prop: "timeAppoint",
            label: "预约时间",
            sortable: true,
            width: 180,
            render: (h, {row}) => {
                const timeStamp = !row.timeAppoint ? "" : timeFormat(new Date(row.timeAppoint), "yyyy-MM-dd HH:mm:ss");
                return h("span", timeStamp);
            }
        },
        {
            prop: "confirmTheTime",
            label: "管家确认时间",
            sortable: true,
            width: 180,
            render: (h, {row}) => {
                const timeStamp = !row.confirmTheTime ? "" : timeFormat(new Date(row.confirmTheTime), "yyyy-MM-dd HH:mm:ss");
                return h("span", timeStamp);
            }
        },
        {
            prop: "openHome",
            label: "确认已看时间",
            width: 180,
            render: (h, {row}) => {
                const timeStamp = !row.openHome ? "" : timeFormat(new Date(row.openHome), "yyyy-MM-dd HH:mm:ss");
                return h("span", timeStamp);
            }
        },
        {
            label: "地址",
            render: (h, {row}) => {
                const {address} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "house-resource", query: {data: {apartmerntName:address}}});
                        }
                    }
                }, address);
            }
        },
        {
            prop: "followCode",
            label: "跟进状态",
            width: 120,
            render: (h, {row}) => {
                const {status} = row;
                //0-预约未看房   2-预约已看房  3-交付定性  4-签约  -5交付定金已过期  6-过期
                let codeName = "";
                switch (status) {
                    case 0:
                        codeName = "待管家确认";
                        break;
                    case 1:
                        codeName = "管家已确认";
                        break;
                    case 2:
                        codeName = "预约已看房";
                        break;
                    case 3:
                        codeName = "交付定金";
                        break;
                    case 4:
                        codeName = "已签约";
                        break;
                    case 5:
                        codeName = "交付定金已过期";
                        break;
                    case 6:
                        codeName = "过期";
                        break;
                }
                return h("span", (codeName));
            }
        },
        {
            prop: "houseManageName",
            label: "管家姓名",
            width: 100
        }
    ];
}
