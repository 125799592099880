<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer
                @click-submit="handleSubmitBuilding" @click-cancel="handleCancel">
        <el-form ref="formBuilding" label-width="110px" size="small" :model="formBuilding" :rules="rules">
            <el-form-item :label="labelName" prop="number">
                <el-input v-model="formBuilding.number"/>
            </el-form-item>
            <el-form-item label="最大楼层数">
                <el-input v-model="formBuilding.layerMax"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {formBuildingRules} from '@/views/rental-management/community-management/data'
import * as communityManagementApi from '@/api/community-management'
import {MessageSuccess, MessageWarning} from '@custom/message'
import {architectureType} from '@/views/rental-management/community-management/data'

export default {
    name: 'layer-add-edit-building',
    data() {
        return {
            dialogTitle: '新增楼幢',
            dialogVisible: false,
            formBuilding: {
                number: 0,
                layerMax: 0,
                uuid: ''
            },
            buildType: 2,
            rules: formBuildingRules(),
            labelName: '楼幢编号',
        }
    },
    methods: {
        //弹框打开方法
        layerAddEditBuildingInit(addEdit, row) {
            // 页面初始化，父页面传递数据
            const {type = 1} = row;

            const {uuid} = row;
            this.formBuilding.uuid = uuid;

            //数据处理，判断类型
            let value = type;
            if (addEdit === 'add') {
                value += 1;
            }
            this.addEdit = addEdit;
            this.buildingType = value;
            const {label} = architectureType.find(v => v.value === value);
            this.labelName = `${label}编号`;
            this.dialogTitle = addEdit === 'add' ? `新增${label}` : `修改${label}`;

            this.dialogVisible = true;
            // 等于编辑回显数据
            if (addEdit === 'edit') {
                this.editBuildingInit(row);
            }
        },

        // 编辑页面回显数据
        editBuildingInit(row) {
            if(this.buildingType === 2){
                const {buildingNumber} = row;
                row.number = buildingNumber;
            }
            if(this.buildingType === 3){
                const {unitNumber} = row;
                row.number = unitNumber;
            }
            const oldFormBuilding = this.formBuilding;
            this.formBuilding = {...oldFormBuilding,...row};
        },

        //弹框确认
        handleSubmitBuilding() {
            // 表单校验
            this.$refs['formBuilding'].validate(valid => {
                if (valid) {
                    //拆分数据
                    const {number, layerMax, uuid} = this.formBuilding;
                    const type = this.buildingType;
                    //封装数据
                    const data = {
                        type, layerMax, communityUuid: '', buildingNumber: '', buildingUuid: '', unitNumber: ''
                    }
                    //根据添加类型赋值
                    if(type === 2) {
                        data.communityUuid = uuid;
                        data.buildingNumber = number;
                    }
                    if(type === 3){
                        data.buildingUuid = uuid;
                        data.unitNumber = number;
                    }

                    //判断类型，选择调用接口
                    const request = this.addEdit === 'add' ? 'addBuildingApi' : 'editBuildingApi';
                    if(this.addEdit !== 'add'){
                        data.number = number;
                        data.uuid = uuid;
                    }
                    //调用接口，提交数据
                    communityManagementApi[request](data).then(() => {
                        MessageSuccess(`${this.dialogTitle}成功!`);
                        this.handleCancel('update');
                    });
                } else {
                    MessageWarning('请填写必填值', 1000);
                    return false;
                }
            })
        },
        // 取消、关闭弹窗
        handleCancel(closeType = 'cancel') {
            // 取消、关闭弹窗
            this.dialogVisible = false;
            this.formBuilding = {}
            this.$refs['formBuilding'].resetFields();
            // 新增、编辑成功，需要刷新数据
            if (closeType === 'update') this.$parent.handleSearch();
        }
    }
}
</script>

<style lang="scss" scoped>
.layer-add-edit-building {
    .el-input-number {
        width: 100%;
    }
}
</style>
