<template>
  <r-e-dialog title="编辑单价" :visible="dialogVisible" show-footer width="780px" @click-submit="handleSubmitPublish"
              @click-cancel="handleCancel" @handleClose="handleCancel">
    <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
      <el-form-item label="房间" prop="name">
        <el-input disabled v-model="formPublish.name"/>
      </el-form-item>
      <el-form-item label="房型" prop="publishUuid">
        <el-select v-model="formPublish.publishUuid" filterable clearable placeholder="请选择房型" style="width: 100%;">
          <el-option v-for="item in publishList" :key="item.uuid" :label="item.templateName"
                     :value="item.uuid"/>
        </el-select>
      </el-form-item>
      <el-form-item label="面积" prop="insideArea">
        <el-input type="number" disabled v-model="formPublish.insideArea">
          <template slot="append">㎡</template>
        </el-input>
      </el-form-item>
      <el-form-item label="单价" prop="monthPriceAmount">
        <el-input v-model="formPublish.monthPriceAmount" @input="getAmountDatablur"
                  :disabled="!getButtonMenu('EDIT_PRICE')">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="服务费" prop="monthPriceAmount">
        <el-input :disabled="!tipAmountDisabled" v-model="formPublish.tipAmount">
          <template slot="append">元/月</template>
        </el-input>
      </el-form-item>
      <div class="flex">
        <el-form-item label="半年租金折扣" prop="sixMonthDiscount">
          <el-input @input="getAmountDatablur" type="number" v-model="formPublish.sixMonthDiscount">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="半年租金">
          <el-input disabled type="number" v-model="amountData.halfRentAmount">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="flex" style="width: 360px;">
        <el-form-item label="半年押金">
          <el-input disabled type="number"  v-model="amountData.halfBoneAmount">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="半年定金">
          <el-input disabled type="number" v-model="amountData.halfDing">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item> -->
      </div>
      <div class="flex">
        <el-form-item label="年租金折扣" prop="yearDiscount">
          <el-input type="number" @input="getAmountDatablur" v-model="formPublish.yearDiscount">
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item label="年租金">
          <el-input disabled type="number" v-model="amountData.yearRentAmount">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
      </div>
      <div class="flex" style="width: 360px;">
        <el-form-item label="年押金">
          <el-input disabled type="number" v-model="amountData.yearBoneAmount">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="年定金">
          <el-input disabled type="number" v-model="amountData.yearDing">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item> -->
      </div>
      <div class="flex" style="width: 360px;">
        <el-form-item label="住建同步:" prop="zjjSyncPublishOrCancel">
          <el-radio v-model="formPublish.zjjSyncPublishOrCancel" label="1">上架</el-radio>
          <el-radio v-model="formPublish.zjjSyncPublishOrCancel" label="2">下架</el-radio>
        </el-form-item>
        <!-- <el-form-item label="年定金">
          <el-input disabled type="number" v-model="amountData.yearDing">
            <template slot="append">元/月</template>
          </el-input>
        </el-form-item> -->
      </div>
      <div>
        <el-form-item label="">
          <!--                    <el-button type="primary" @click="getAmountData">计算金额</el-button>-->
        </el-form-item>
      </div>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {ParseFloatFormat, ParseIntFormat} from "@custom/index";
import {
  apartmentDiscountAmountUpdate,
  apartmentDiscountAmountInfo,
  bzfGetAmount,
  getHouseList
} from "@/api/house-resource";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {buttonCookies} from "@storage/cookies";
import {getPublishList} from "@/api/publish";
import el from "element-ui/src/locale/lang/el";

export default {
  name: "dialog-edit-detail-bzf-unit-price",
  data() {
    //自定义区间值校验
    let validatePass = (rule, value, callback) => {
      value = Number(value);
      if (value < this.remarkmin || value > this.remarkmax) {
        callback(new Error(`请输入${this.remarkmin} ~ ${this.remarkmax}之间的数值`));
      } else if (value % 1 !== 0) {
        callback(new Error('请输入整数值'));
      } else {
        callback();
      }
    };
    return {
      tipAmountDisabled: true,
      disabled: false,
      dialogVisible: false,
      remarkmin: null,
      remarkmax: null,
      formPublish: {
        name: null,
        apartmentUuid: null,
        monthPriceAmount: null,
        sixMonthDiscount: null,
        yearDiscount: null,
        publishUuid: null,
        outsideArea: null,
        insideArea: null,
        tipAmount: null,
        zjjSyncPublishOrCancel:'',
      },
      rules: {
        zjjSyncPublishOrCancel:[{required: true, message: "请选择是否下架", trigger: "blur"},],
        monthPriceAmount: [{required: true, message: "请输入单价", trigger: "blur"},],
        sixMonthDiscount: [
          {required: true, message: "请输入半年折扣", trigger: "blur"},
          {validator: validatePass, trigger: 'blur'}
        ],
        yearDiscount: [
          {required: true, message: "请输入年折扣", trigger: "blur"},
          {validator: validatePass, trigger: 'blur'}
        ],
        publishUuid: [{required: true, message: "请选择房型", trigger: "change"},],
        outsideArea: [{required: true, message: "请输入建筑面积", trigger: "blur"},],
        insideArea: [{required: true, message: "请输入使用面积", trigger: "blur"},],
      },
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      publishList: [],
      amountData: {
        halfBoneAmount: null,
        halfDing: null,
        halfRentAmount: null,
        yearBoneAmount: null,
        yearDing: null,
        yearRentAmount: null,
      }
    };
  },
  created() {
    this.tipAmountDisabled = this.getButtonMenu('TIPAMOUNTDISALED')

  },
  methods: {

    //打开保租房编辑单价弹框
    async openDialog(data) {
      let that = this;
      let {list} = await getPublishList();
      const publishList = list
      //保存旧数据
      const oldFormPublish = that.formPublish;
      //保存房型
      this.publishList = publishList;
      //解构数据
      let {uuid, name, publishUuid} = data;
      let itr = ''
      // alert(publishUuid)
      if (publishUuid != null) {
        itr = publishList.find(item => {
          return item.uuid === publishUuid
        })
      }
      if (itr === undefined || publishUuid === null) {
        publishUuid = ''
      }
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //调用接口获取，房间信息，房间金额
      let {info} = await apartmentDiscountAmountInfo({uuid});
      console.log('info',info);
      let reslist = await getHouseList({apartmentUuid: uuid})
      console.log('reslist',reslist);
      if (reslist.list.length === 0) {
        MessageWarning('该房间为合租主房间，不支持编辑租金');
        loading.close();
        return; // 停止函数执行
      }
      that.remarkmin = reslist.list[0].value;
      that.remarkmax = reslist.list[1].value;
      let {sixMonthDiscount, yearDiscount, monthPriceAmount, outsideArea, insideArea, tipAmount, mainUuid} = info;
      //金额转换
      monthPriceAmount = ParseFloatFormat(monthPriceAmount);
      // 陈守亮-2024-1-18-修改折扣
      if (mainUuid !== null) that.disabled = true
      //调用接口，获取房间所有计算后金额
      let res = await bzfGetAmount({uuid, monthPriceAmount: null, yearDiscount: null, sixMonthDiscount: null});
      //解构金额
      let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount,} = res.info;
      //金额转换
      halfBoneAmount = ParseFloatFormat(halfBoneAmount);
      halfDing = ParseFloatFormat(halfDing);
      halfRentAmount = ParseFloatFormat(halfRentAmount);
      yearBoneAmount = ParseFloatFormat(yearBoneAmount);
      yearDing = ParseFloatFormat(yearDing);
      yearRentAmount = ParseFloatFormat(yearRentAmount);
      tipAmount = ParseFloatFormat(tipAmount)
      //封装房间数据
      that.formPublish = {
        ...oldFormPublish, apartmentUuid: uuid, name, publishUuid, sixMonthDiscount, yearDiscount,
        monthPriceAmount, outsideArea, insideArea, tipAmount,
      }
      //封装金额数据
      that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
      // this.formPublish.sixMonthDiscount = 100
      //  this.formPublish.yearDiscount = 100
      // loading.close();
      // //打开弹窗
      // this.dialogVisible = true;
      // that.formPublish.publishUuid = publishUuid
      //   setTimeout(() => {
      loading.close();
      this.dialogVisible = true;
      if (itr === undefined) {
        MessageWarning('该房型已不存在，请重新选择')
      }
      // },2000)

    },

    //点击确认按钮事件
    handleSubmitPublish() {
      // this.getAmountData()
      let that = this;

      // console.log('099999')
      // 表单校验
      this.$refs["formPublish"].validate((valid) => {
        if (valid) {
          // that.submitgetAmountData()
          //解构数据
          let {
            apartmentUuid, monthPriceAmount, sixMonthDiscount, yearDiscount, publishUuid, outsideArea,
            insideArea, tipAmount,zjjSyncPublishOrCancel
          } = that.formPublish;
          //金额转换
          monthPriceAmount = ParseIntFormat(monthPriceAmount);
          tipAmount = ParseIntFormat(tipAmount);
          //封装数据
          const params = {
            apartmentUuid, monthPriceAmount, sixMonthDiscount, yearDiscount, publishUuid, outsideArea,
            insideArea, tipAmount,zjjSyncPublishOrCancel
          }
          const loadingOptions = that.loadingOptions;
          const loading = that.$loading({...loadingOptions});
          //调用接口，编辑保租房房间单价
          apartmentDiscountAmountUpdate(params).then(res => {
            MessageSuccess("编辑成功");
            //调用取消按钮事件，关闭弹框
            that.handleCancel();
          }).finally(() => loading.close());
        } else {
          return false;
        }
      });
    },
    //点击取消按钮事件
    handleCancel() {
      // console.log('取消1')
      //清空表单
      this.$refs["formPublish"].resetFields();
      // 取消、关闭弹窗
      this.dialogVisible = false;
      //调用上层方法，刷新房间信息
      this.$emit("handleSearch");
    },
    //陈守亮-2024-2-27-编辑单价自己修改
    getAmountDatablur() {
      setTimeout(() => {
        this.getAmountData()

      }, 3000)
    },
    //点击计算金额事件
    async getAmountData(e) {
      let that = this;
      const loadingOptions = that.loadingOptions;
      const loading = that.$loading({...loadingOptions});
      //解构数据，获取请求参数
      let {apartmentUuid: uuid, monthPriceAmount, yearDiscount, sixMonthDiscount} = that.formPublish;
      //金额转换
      monthPriceAmount = ParseIntFormat(monthPriceAmount);
      //调用接口，获取房间所有计算后金额
      let res = await bzfGetAmount({
        uuid,
        monthPriceAmount,
        yearDiscount,
        sixMonthDiscount
      }).finally(() => loading.close());
      if (res) {
        //解构金额
        let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount} = res.info;
        //金额转换
        halfBoneAmount = ParseFloatFormat(halfBoneAmount);
        halfDing = ParseFloatFormat(halfDing);
        halfRentAmount = ParseFloatFormat(halfRentAmount);
        yearBoneAmount = ParseFloatFormat(yearBoneAmount);
        yearDing = ParseFloatFormat(yearDing);
        yearRentAmount = ParseFloatFormat(yearRentAmount);
        //封装金额数据
        that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
      }
    },
    // async submitgetAmountData() {
    //    let that = this;
    //    //解构数据，获取请求参数
    //    let {apartmentUuid: uuid, monthPriceAmount, yearDiscount, sixMonthDiscount} = that.formPublish;
    //    //金额转换
    //    monthPriceAmount = ParseIntFormat(monthPriceAmount);
    //    //调用接口，获取房间所有计算后金额
    //    let res = await bzfGetAmount({uuid, monthPriceAmount, yearDiscount, sixMonthDiscount}).finally(() => {that.handleSubmitPublish()});
    //    if(res){
    //      //解构金额
    //      let {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount} = res.info;
    //      //金额转换
    //      halfBoneAmount = ParseFloatFormat(halfBoneAmount);
    //      halfDing = ParseFloatFormat(halfDing);
    //      halfRentAmount = ParseFloatFormat(halfRentAmount);
    //      yearBoneAmount = ParseFloatFormat(yearBoneAmount);
    //      yearDing = ParseFloatFormat(yearDing);
    //      yearRentAmount = ParseFloatFormat(yearRentAmount);
    //      //封装金额数据
    //      that.amountData = {halfBoneAmount, halfDing, halfRentAmount, yearBoneAmount, yearDing, yearRentAmount};
    //    }
    //  },
    //获取按钮权限数组
    getButtonMenu(menu) {
      return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
    },
  }
}
</script>

<style scoped>

</style>
