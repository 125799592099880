<template>
  <section class="card-house flex flex-warp " style="padding-left: 50px;margin-top: 12px" >
    <!--    :class="`room_status_${item.isLetOutRent}` "-->

    <div
        class="house-item container"
        v-for="item in data"
        :key="item.apartmentUuid"
        :class="`room_status_${item.paymentDisabled}`"
        @click="handleClickHouse(item)"
    >
      <!--      {{item}}-->
      <div class="header flex align-center">
        <div class="house-name">{{ item.roomNum }}</div>
        <div class="lessee-number">（{{ item.leasorNum || 0 }}人）</div>
      </div>
      <div class="main">
        <div
            class="lessee-item flex align-center"
            v-for="lessee in houseOptions"
            :key="`${item.uuid}_${lessee.name}`"
        >
          <div class="label" style="width: 80px">{{ lessee.label }}：</div>
          <div class="value" style="margin-left: -35px">
            <span>{{ item[lessee.name] }}</span>
            <span v-if="lessee.name === 'leasorName'">
<!--              陈守亮-2024-4-30-富航房源管理卡片新增性别区分-->
              {{ item.leasorPhone }} {{item.sex}}</span>
            <!--            2024-4-24-陈守亮--->
            <span v-if="item[lessee.name]"> {{ lessee.unit }}</span>
          </div>
        </div>
      </div>
      <div class="footer flex align-center justify-end" style=" position:absolute;left: 80%;top: 85%">
        <el-tooltip placement="top" effect="light" >
          <div slot="content">{{ item.waterAmount }}</div>
          <img
              class="footer-icon"
              :title="createIcon('water', item.water).title"
              :src="createIcon('water', item.water).src"
              alt=""
          />
        </el-tooltip>

        <el-tooltip placement="top" effect="light">
          <div slot="content">{{ item.electAmount }}</div>
          <img
              class="footer-icon"
              :title="createIcon('elect', item.elect).title"
              :src="createIcon('elect', item.elect).src"
              alt=""
          />
        </el-tooltip>
        <img
            class="footer-icon"
            :title="createIcon('intelLock', item.intelLock).title"
            :src="createIcon('intelLock', item.intelLock).src"
            alt=""
        />
      </div>

      <div class="room-status " >
        <el-badge v-if="item.vacancyDay === null" type="warning">
          <el-tag
              :class="`room_status_son${item.paymentDisabled}`"
              style="margin-left: 15px"
              :type="createRoomStatus(item.state).type"
              size="medium"
              effect="dark"
          >
            {{ createRoomStatus(item.state).codeName }}

          </el-tag>
        </el-badge>
        <el-badge :value="item.vacancyDay" v-else type="warning">
          <el-tag
              :class="`room_status_son${item.paymentDisabled}`"
              style="margin-left: 15px"
              :type="createRoomStatus(item.state).type"
              size="medium"
              effect="dark"
          >
            {{ createRoomStatus(item.state).codeName }}
          </el-tag>
        </el-badge>
        <div style="height: 8px"></div>
        <el-tag
            :class="`room_status_son${item.paymentDisabled}`"
            type="success"
            size="medium"
            effect="dark"
            style="margin-left: 5px"
            v-if="item.isLetOutRent === 1"
        >不对外</el-tag>

        <div style="height: 8px"></div>
        <el-tag style="margin-left: 15px"
                @click="jiejinclick(item,false)"
                type="danger"
                size="medium"
                effect="dark"
                v-if="item.paymentDisabled === false && createRoomStatus(item.state).codeName === '已租'"
        >禁用</el-tag>
        <el-tag
            @click="jiejinclick(item,true)"
            type="success"
            size="medium"
            effect="dark"
            style="margin-left: 15px"
            v-if="item.paymentDisabled === true"
        >解禁</el-tag>
      </div>
    </div>
  </section>
</template>

<script>
import {
  houseOptions,
  houseDevice,
} from "@/views/rental-management/house-resource/data";
import {getHouseListByCommunityfalse, getHouseListByCommunitysiok} from "../../../../api/house-resource";
import el from "element-ui/src/locale/lang/el";
import {MessageSuccess} from "@custom/message";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    houseStatus: {
      type: Array,
      default: () => [],
    },
  },
  name: "card-house",
  data() {
    return {
      itr:false,
      houseOptions,
      isActive:null
    };
  },
  methods: {
    //房源卡片点击事件
    handleClickHouse(item) {
      if(this.itr) return
      //调用上层传递方法
      this.$emit("on-click", item);
    },
    // 获取水、电、智能锁状态图标
    createIcon(name, boolean) {
      // console.log(this.houseOptions)
      // 水、电、智能锁状态图标
      const iconName = boolean ? "" : "_bed";
      const statusName = boolean ? "正常" : "损坏";
      const { label } = houseDevice.find((v) => v.name === name);
      const title = label + statusName;
      const src = require(`../../../../assets/images/house/${name}${iconName}.png`);
      return { title, src };
    },
    // 获取房间状态
    createRoomStatus(status) {
      // 房间状态
      if (!this.houseStatus.map((v) => v.code).includes(status)) return "--";
      const { codeName, dataValue } = this.houseStatus.find(
          (v) => v.code === status
      );
      return { type: dataValue, codeName };
    },
    //   鼠标移入事件
    //   onmouseover(index){
    //     console.log('移入了')
    //     this.isActive = index
    //   }


    //   2024-6-6-陈守亮-禁用事件
    jiejinclick(row,isvalue){
      console.log(row)
      let {apartmentUuid} = row
      this.itr = true
      let titlevalue = '是否禁用所选房间？'
      let messagevalue = '禁止缴费成功！'
      if(isvalue) {
        titlevalue = '是否解禁所选房间'
        messagevalue = '解禁成功！'
      }
      this.$confirm(titlevalue, '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(async () => {
            if(isvalue){
              // 解开禁用接口
              await  getHouseListByCommunityfalse({uuids:[apartmentUuid]}).then(async (res) => {
                console.log(res)
                await  this.$emit('handleSearch');
                this.$message({
                  message: messagevalue,
                  type: 'success'
                });
              })
            }else {
              // 开始禁用接口
              await  getHouseListByCommunitysiok({uuids:[apartmentUuid]}).then(async(res) => {
                console.log(res)
                await  this.$emit('handleSearch');
                this.$message({
                  message: messagevalue,
                  type: 'success'
                });
              })
            }
            this.itr = false
          })
      .catch(action => {
        this.itr = false
        // if(isvalue){
        //   this.$message({
        //     type: 'info',
        //     message: '解禁失败,请联系管理员'
        //   })
        // }

      });

    }
  },
};
</script>

<style lang="scss" scoped>
.container:hover {
  transition: all 0.5s;
  //-webkit-transition: all 0.25s;
  transform: scale(1.1);
}

.room_status_true{
  //background: #ff6347 !important;
  //opacity: 0.2;
  background-color: rgb(245,108,108) !important;
  //rgba(176,124,198, 0.5);
}
.room_status_sontrue{
  opacity: 0.2;
}
//.room_status_1:before {
//  opacity: 1;
//  z-index: -1;
//}
//.child {
//  background-color: #249dde;
//  opacity: 1; /* 子元素的透明度不会受到父元素的影响 */
//  z-index:  999999;
//}
.card-house {
  .house-item {
    min-width: 140px;
    min-height: 135px;
    width: VW(270px);
    height: VH(160px);
    color: #fff;
    border-radius: 4px;
    background: #68acfd;

    padding: VH(10px) VW(20px);
    margin: 0 0 VH(20px) VW(20px);
    position: relative;
    cursor: pointer;

    .header {
      padding-bottom: VH(10px);

      .house-name {
        font-size: rem(25px);
      }
    }

    .main {
      opacity: 0.63;
      font-size: rem(13px);

      .lessee-item {
        padding-bottom: VH(5px);
      }
    }

    .footer-icon {
      width: VW(14px);
      padding-right: VW(5px);
    }

    .room-status {
      font-size: rem(14px);
      position: absolute;
      top: VH(5px);
      right: VW(1px);
      height: 120px;
    }
  }
}
</style>
