<template>
    <section class="asset">
        <!--        <r-e-table ref="communityTableRef" :data="unPaidTableData" :columns="houseAssetColumns" :height="750"
                           :show-pagination="false" row-key-id="uuid">
                    <el-table-column slot="toolbar" label="操作" width="120">
                        <template slot-scope="{ row }">
                            <div class="table-tools">
                                              <span class="table-btn" v-if="row.code" @click="addAsset(row)">
                                                   <i class="el-icon-circle-plus-outline"
                                                      style="color: #2386F6;font-size: 18px"></i>
                                              </span>
                                &lt;!&ndash;2022/03/21 王江毅 行数据 修改图标&ndash;&gt;
                                &lt;!&ndash;<span class="table-btn" v-if="row.code">
                                     <i class="el-icon-edit" style="color: #2386F6;font-size: 18px"></i>
                                </span>&ndash;&gt;
                                &lt;!&ndash;2022/03/21 王江毅 行数据 删除图标&ndash;&gt;
                                &lt;!&ndash;<span class="table-btn" v-if="row.code">
                                     <i class="el-icon-delete" style="color: #E96443;font-size: 18px"></i>
                                </span>&ndash;&gt;
                                <span class="table-btn" v-if="!row.code&&row.state==='在用'" @click="repair(row,700103)">
                                                   <img src="@/assets/images/house/u1002.png" width="18" height="18" alt=""/>
                                              </span>
                                <span class="table-btn" v-if="!row.code&&row.state==='在用'" @click="repair(row,700104)">
                                                   <img src="@/assets/images/house/u1003.png" width="18" height="18" alt=""/>
                                              </span>
                                <span class="table-btn" v-if="!row.code&&row.state==='在用'" @click="allocation(row)">
                                                   <img src="@/assets/images/house/u1004.png" width="18" height="18" alt=""/>
                                              </span>
                            </div>
                        </template>
                    </el-table-column>
                </r-e-table>-->
        <el-button type="primary" size="small" @click="exportAllTag">批量导出标签</el-button>
        <r-e-table ref="communityTableRef" :dataRequest="getApartmentAssetsList" :columns="assetColumns" :height="750"
                   :show-pagination="false" border @selection-change="handleSelectionChange">
            <el-table-column slot="toolbar" label="操作" width="120">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="print(row)"> 导出标签 </span>
                        <span class="table-btn" @click="goXiangQing(row)"> 详情 </span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <!--资产分配-->
        <dialog-add-asset ref="dialogAddAsset" :currentHouse="currentHouse"/>
        <!--资产报修-->
        <dialog-repair ref="dialogRepair"/>
        <!--资产调拨-->
        <dialog-asset-allocation ref="dialogAssetAllocation"/>

        <layer-label-preview ref="layerLabelPreview"/>
    </section>
</template>

<script>
import {houseAssetColumns, assetColumns} from "@/views/rental-management/house-resource/data";
import {getAssetsByCodeandUuid, getApartmentAssetsList} from "@/api/assets";
import jcPrinterSdk from '@/assets/js/jcPrinterSdk_api_third'
import {MessageInfo} from '@custom/message'
import {combinationPrintData, fangjianming} from "@/views/property-management/assets-management/part/js/label-template";

export default {
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        },
      zUuid: {
        type: String,
        default: null,
      },
    },
    name: "asset",
    data() {
        return {
            assetColumns,
            houseAssetColumns,
            unPaidTableData: [],
            loadingOptions: {
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            },
            labelSelectArray: [],
        };
    },
    components: {
        dialogAddAsset: () => import("./components/dialog-add-asset"),
        dialogRepair: () => import("./components/dialog-repair"),
        dialogAssetAllocation: () => import("./components/dialog-asset-allocation"),
        layerLabelPreview: () => import('@/views/property-management/assets-management/part/components/layer-label-preview')
    },
    async mounted() {
      const {apartmentUuid} = this.currentHouse;
      let uuid = this.zUuid || apartmentUuid;
        //初始化打印机服务
        jcPrinterSdk.jcPrinterInit()
        //2022/03/21 王江毅 获取房源UUID 暂时没有用,先留着以后有用
        /*const {apartmentUuid} = this.currentHouse;
        let list = await this.$store.dispatch('app/getDictionaryByCodeActions', '200000');
        for (let item of list) {
            let {list: childrenList} = await getAssetsByCodeandUuid(item.code, apartmentUuid);
            item.children = childrenList;
            this.unPaidTableData.push(item);
        }*/
    },
    methods: {
        //获取房间资产
        getApartmentAssetsList(params) {
            const {apartmentUuid} = this.currentHouse;
            // params.uuid = this.zUuid || apartmentUuid;
          params.apartmentUuid = this.zUuid || apartmentUuid;
          return getApartmentAssetsList(params);
        },

        // 打开资产报修弹窗
        repair(data, type) {
            const {apartmentUuid, address} = this.currentHouse;
            data.apartmentUuid = apartmentUuid;
            data.apartmentName = address;
            this.$refs["dialogRepair"].openDialog(data, type);
        },

        // 打开资产调拨弹窗
        allocation(data) {
            const {apartmentUuid, address} = this.currentHouse;
            data.oldCompanyUuid = apartmentUuid;
            data.apartmentName = address;
            data.assetsUuid = data.uuid;
            this.$refs["dialogAssetAllocation"].openDialog(data);
        },

        // 跳转资产详情
        goXiangQing(data) {
            this.$emit("closeDrawer");
            this.$router.push({name: "assets-warehousing"});
        },

        // 导出标签
        print(data) {
            //解构数据
            const {assets, apartment} = data;
            //重新组装数据
            if (apartment) {
                const {name} = apartment;
                data.name = name;
            }
            if (assets) {
                const {assetType, assetName} = assets;
                data.assetType = assetType;
                data.asstesName = assetName;
            }
            //打开预览弹窗
            this.$refs['layerLabelPreview'].openDialog(data);
        },

        // 多选框选中数据
        handleSelectionChange(e) {
            const labelSelectArray = [];
            //处理选择的数据
            e.map((item) => {
                let {uuid, apartment: {name}, assets: {assetType: assetType, assetName: asstesName}} = item;
                const {InitDrawingBoardParam, elements} = JSON.parse(JSON.stringify(combinationPrintData));
                elements[0].json.value = `https://www.ctfhmy.com/h5/assets/index.html?uuid=${uuid}_;`;
                elements[1].json.value = '南宁富航公司\n' + asstesName;
                if (name) {
                    const fangjianming1 = JSON.parse(JSON.stringify(fangjianming));
                    elements.push(fangjianming1);
                    elements[2].json.value = name;
                    elements[2].json.fontSize = 2.8//陈守亮新增控制打印字体
                }
                labelSelectArray.push({InitDrawingBoardParam, elements});
                return {uuid, name, assetType}
            })
            this.labelSelectArray = labelSelectArray;
        },

        // 批量导出标签
        exportAllTag() {
            const that = this;
            //判断标签数量是否为0
            const {labelSelectArray} = that;
            if (labelSelectArray.length === 0) {
                MessageInfo('请先选择打印标签');
                return
            }

            that.$confirm(`您已勾选${labelSelectArray.length}个资产，确认打印标签？`, '注意', {type: 'warning'}).then(() => {
                const loadingOptions = that.loadingOptions;
                that.loading = that.$loading({...loadingOptions});
                //调用批量打印
                jcPrinterSdk.batchPrintJob(that, labelSelectArray, false);
            }).catch(() => {});
        },

        /*addAsset(data) {
            this.$refs["dialogAddAsset"].openDialog(data);
        },*/
    }
};
</script>

<style lang="scss" scoped>
.asset {
    /deep/ .r-e-table {
        .el-table .cell {
            //white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
