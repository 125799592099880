import {timeFormat, numberFormat} from "@custom/index";
import {conversionAmount} from "@/utils/hooks";

export function tableColumn(_this) {
    return [
        {
            type: "index",
            label: "序号",
            width: 100
        },
        {
            // 2023-9--22-11-10-陈守亮再次优化超链接跳转
            prop: "contractNum",
            label: "合同编号",
            width: 150,
            render: (h, {row}) => {
                const {electContractType, uuid} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            if (electContractType === 3) {
                                _this.$alert('线下签约', '提示', {confirmButtonText: '确定',});
                                return;
                            }

                            _this.lookDianZiHeTong(uuid, electContractType);
                        }
                    }
                }, row.contractNum);
            },
        },
        {
            prop: "finished",
            label: "合同状态",
            width: 100,
            render: (h, {row}) => {
                let {statusCode: code, statusCodeName: codeName} = row;
                let type = "";
                switch (code) {
                    case 500701:
                        type = "success";
                        break;
                    case 500702:
                        type = "info";
                        break;
                    case 500703:
                        type = "warning";
                        break;
                    case 500704:
                        type = "";
                        break;
                    case 500705:
                        type = "danger";
                        break;
                    case 500706:
                        type = "success";
                        break;
                }
                if (code === 500709) codeName = "待完善";
                return h("el-tag", {props: {type: type, size: "small"}}, codeName);
            }
        },
        {
            prop: "natureCode",
            label: "合同性质",
            width: 100,
            render: (h, {row}) => {
                const {natureCode} = row;
                return h("span", natureCode === 500601 ? "企业合同" : natureCode === 500602 ? "个人合同" : "暂无数据");
            }
        },
        {
            label: "租户名称",
            width: 100,
            render: (h, {row}) => {
                const {leasorName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({name: "tenant-management", params: {data: {leasorName}}});
                        }
                    }
                }, leasorName);
            }
        },
        {
            prop: 'lesseeIdCard',
            label: '身份证号',
            width: 200,
        },
        {
            prop: "phone",
            label: "租户手机号",
            width: 100
        },
        {
            prop: "district",
            label: "区域",
            width: 150
        },
        {
            label: "房源名称",
            width: 200,
            // showOverflowTooltip: true,
            render: (h, {row}) => {
                const {apartmentName} = row;
                return h("el-link", {
                    props: {type: "primary", underline: false},
                    on: {
                        click() {
                            _this.$router.push({
                                name: "house-resource",
                                query: {data: {apartmerntName: apartmentName}}
                            });
                        }
                    }
                }, apartmentName);
            }
        },

        // {
        //     prop:'payDepoAmount',
        //     label:'房租金额(元)'
        // },

        {
            prop: "amount",
            label: "房租金额(元)",
            width: 100,
            render: (h, {row}) => {
                const {amount} = row;
                return h("span", amount ? numberFormat(amount / 100) : "暂无数据");
            }
        },
        {
            prop: 'payDepoAmount',
            label: '押金金额(元)',
            width: 100,
            render: (h, {row}) => {
                const {payDepoAmount} = row;
                return h("span", payDepoAmount ? numberFormat(payDepoAmount / 100) : "暂无数据");
            }
        },
        {
            prop: "startDate",
            label: "合同开始时间",
            width: 150,
            sortable: true
        },
        {
            prop: "endDate",
            label: "合同结束时间",
            width: 150,
            sortable: true
        },
        {
            prop: "terminatedDate",
            label: "合同终止时间",
            width: 150,
            render: (h, {row}) => {
                const {terminatedDate} = row;
                if (terminatedDate) return h("span", timeFormat(new Date(terminatedDate)));
            },
            sortable: true
        },
        // 张晓瑜新增
        {
            prop: "signDate",
            label: "签约时间",
            width: 150,
        },
        // 2024/05/07 张晓瑜新增
        {
            prop: "nullifyDate",
            label: "作废时间",
            width: 150,
            render: (h, {row}) => {
                const {nullifyDate} = row;
                return h("span", nullifyDate ? timeFormat(new Date(nullifyDate),"yyyy-MM-dd HH:mm:ss") : "");
            },
            sortable: true
        },
        // {
        //     prop: "checkOutDate",
        //     label: "退租时间",
        //     width: 150,
        // },
        {
            prop: "area",
            label: "建筑面积（㎡）",
            width: 150,
        },
        {
            prop: "manager",
            label: "经办人",
        },
        {
            prop: "keeper",
            label: "管理人",
        },
        {
            prop: "nullifyUserName",
            label: "作废人",
        },
        {
            prop: "isRecordOrNot",
            label: "备案信息",
            render: (h, {row}) => {
                const {isRecordOrNot} = row;
                if(isRecordOrNot === 0) return ''
                if(isRecordOrNot === 1) return '备案成功'
                if(isRecordOrNot === 2) return '备案未成功'
                if(isRecordOrNot === 3) return '发起失败'

                // if (electContractType !== 3) {
                //     return h("el-link", {
                //         props: {type: "primary", underline: false}, on: {
                //             click() {
                //                 _this.lookDianZiHeTong(uuid, electContractType);
                //             }
                //         }
                //     }, "查看");
                // } else return h("span", "见房源详情");
            },
        },
        {
            prop: "haveMoFangContract",
            label: "电子合同",
            width: 150,
            render: (h, {row}) => {
                const {uuid, electContractType} = row;
                if (electContractType !== 3) {
                    return h("el-link", {
                        props: {type: "primary", underline: false}, on: {
                            click() {
                                _this.lookDianZiHeTong(uuid, electContractType);
                            }
                        }
                    }, "查看");
                } else return h("span", "见房源详情");
            },
        },
        {
            prop: "comment",
            label: "备注",
            showOverflowTooltip: true
        },
        /*{
            prop: "billingStartDate",
            label: "账单时间",
            width: 150
        },
        {
            prop: "signedDate",
            label: "签约时间",
            width: 150
        },
        {
            prop: "terminatedDate",
            label: "合同终止时间",
            width: 150
        },
        {
            prop: "signed",
            label: "签名",
            width: 100
        },
        {
            prop: "finished",
            label: "合同是否结束",
            width: 100
        },
        {
            prop: "monthlyRent",
            label: "月租金",
            width: 100
        },
        {
            prop: "depositAmount",
            label: "显示金额",
            width: 100
        },
        {
            prop: "rentalAmount",
            label: "租金",
            width: 100
        },
        {
            prop: "refund",
            label: "退款状态",
            width: 100
        },
        {
            prop: "refundedAmount",
            label: "退款金额",
            width: 100
        },
        {
            prop: "comment",
            label: "备注"
        },
        {
            prop: "operatorTime",
            label: "操作时间",
            width: 150
        },
        {
            prop: "createTime",
            label: "创建时间",
            width: 150
        },*/
        /*{
            label: "操作",
            render: (h, {row}) => {

                return [
                    /!*h("el-button",{
                        props:{
                            type: "success",
                            size: "mini"
                        },
                        on:{
                            click: ()=>{ _this.xuzu(row); }
                        },
                        style: {
                            marginRight:"5px"
                        }
                    }, "续签"),*!/
                    h("el-button", {
                        props: {
                            type: "danger",
                            size: "mini"
                        },
                        on: {
                            click: () => {
                                _this.openCheckOut(row);
                            }
                        },
                        style: {
                            marginRight: "5px"
                        }
                    }, "退房"),
                    /!*h("el-button",{
                        props:{
                            type: "danger",
                            size: "mini"
                        },
                        on:{
                            click: ()=>{ }
                        },
                        style: {
                            marginRight:"5px"
                        }
                    }, "终止"),*!/
                ]
            }
        },*/
    ];
}

// 个人合同 - 退房 - 基本信息 - 详情
export const checkOutEssentialInformation = [
    {
        label: "承租人",
        name: "leasorName"
    },

    {
        label: "退房时间",
        name: "terminatedDate",
        render: (h, {data}) => {
            return h("el-date-picker", {
                props: {
                    value: data.terminatedDate,
                    type: "date",
                    "value-format": "yyyy-MM-dd",
                    "default-value": new Date()
                },
                on: {
                    //2022/03/21 王江毅 el-date-picker组件 input事件 用于绑定terminatedDate
                    input: (e) => {
                        data.terminatedDate = e;
                    }
                }
            }, "");
        }
    },
    {
        label: "房源",
        name: "apartmentName"
    },
];


// 个人合同 - 退房 - 费用汇总 - 详情
export const checkOutCostAggregation = [
    {
        label: "保证金",
        name: "depositAmount",
        render: (h, {value}) => {
            return conversionAmount(value);
        }
    },
    {
        label: "房租",
        name: "monthlyRent",
        render: (h, {value}) => {
            return conversionAmount(value);
        }
    },
    {
        label: "水表",
        name: "",
        render: (h, {data}) => {
            return [
                h("el-tag",
                    {
                        props: {
                            type: "warning"
                        },
                        style: {
                            marginRight: "10px"
                        }
                    },
                    data.degreeWater + " m³ "
                ),
                h("el-tag",
                    {
                        props: {
                            type: "warning"
                        },
                    },
                    conversionAmount(data.amontWater)
                ),
            ];
        }
    },
    {
        label: "电表",
        name: "",
        render: (h, {data}) => {
            return [
                h("el-tag",
                    {
                        props: {
                            type: "warning"
                        },
                        style: {
                            marginRight: "10px"
                        }
                    },
                    data.degreeElectric + " kW·h "
                ),
                h("el-tag",
                    {
                        props: {
                            type: "warning"
                        },
                    },
                    conversionAmount(data.amountElectric)
                ),
            ];
        }
    },
    {
        label: "其他费用",
        name: "amountOther",
        render: (h, {value}) => {
            return conversionAmount(value);
        }
    },
    {
        label: "余额",
        name: "balance",
        render: (h, {value}) => {
            return conversionAmount(value);
        }
    }
];



