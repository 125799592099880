<template>
    <r-e-dialog :title="title" class="layer-add-house" :visible.sync="dialogVisible" show-footer top="10vh"
                @click-submit="handleSubmitHouse" @click-cancel="handleCancel" ref="redgfrom">
        <el-form ref="formHouse" label-width="110px" size="small" :model="formHouse" :rules="houseRules">
            <el-form-item label="房源类型" prop="propertyCode">
                <el-select v-model="formHouse.propertyCode" placeholder="请选择房源类型">
                    <el-option v-for="item in houseTypes" :key="item.code" :label="item.codeName" :value="item.code"/>
                </el-select>
            </el-form-item>
            <el-form-item label="楼层号" prop="floorNumber">
                <el-input-number v-model="formHouse.floorNumber" :min="1" controls-position="right"
                                 placeholder="请填写楼层号"/>
            </el-form-item>
            <el-form-item label="房间号" prop="roomNumber">
                <el-input v-model="formHouse.roomNumber" placeholder="请填写房间号"/>
            </el-form-item>
            <el-form-item label="建筑面积" prop="outsideArea">
                <el-input type="number" v-model="formHouse.outsideArea" placeholder="请填写建筑面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="使用面积" prop="insideArea">
                <el-input type="number" v-model="formHouse.insideArea" placeholder="请填写使用面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <el-form-item label="车棚面积" prop="parkingArea">
                <el-input type="number" v-model="formHouse.parkingArea" placeholder="请填写车棚面积">
                    <template slot="append">㎡</template>
                </el-input>
            </el-form-item>
            <!--            <el-form-item label="车棚面积">
                            <el-select v-model="formHouse.apartmentType" placeholder="请选择房源类型">
                                <el-option v-for="item in houseApartmentType" :label="item" :value="item" :key="item"/>
                            </el-select>
                        </el-form-item>-->
            <el-form-item label="平面图" prop="ichnography">
                <upload-avatar @on-success="handleUploadSuccess" :uuid="formHouse.ichnography"/>
            </el-form-item>
            <el-form-item label="房源地址" prop="addresss">
                <el-input v-model="formHouse.addresss" placeholder="请填写房源地址"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
import uploadAvatar from "@/components/Upload/upload-avatar";
import {houseApartmentType} from "@/views/rental-management/house-resource/data";
import {addHouseApi, putHouseDetailApi, getUpdateApartmentDetails} from "@/api/house-resource";
import {MessageSuccess} from "@custom/message";

export default {
    name: "layer-add-house",
    props: ['title'],
    data() {
        return {
            houseApartmentType,
            dialogVisible: false,
            houseTypes: [],
            formHouse: {
                propertyCode: null,
                floorNumber: 0,
                roomNumber: null,
                outsideArea: null,
                insideArea: null,
                parkingArea: null,
                apartmentType: '',
                ichnography: null,
                addresss: null,
            },
            uuid: null,
            houseRules: {
                propertyCode: [{required: true, message: '请选择房源类型', trigger: 'change'}],
                floorNumber: [{required: true, message: '请填写楼层号', trigger: 'blur'}],
                roomNumber: [{required: true, message: '请填写房间号', trigger: 'blur'}],
                outsideArea: [{required: true, message: '请填写建筑面积', trigger: 'blur'}],
                insideArea: [{required: true, message: '请填写使用面积', trigger: 'blur'}],
                addresss: [{required: true, message: '请填写房源地址', trigger: 'blur'}],
            },
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            },
        };
    },
    components: {uploadAvatar},
    async mounted() {
        // 获取房源类型,保障性租赁住房600210,长租公寓600220
        this.houseTypes = await this.$store.dispatch("app/getDictionaryByCodeActions", "600210,600220");
    },
    methods: {
        // 父组件传递过来的数据
        layerAddHouseInit(numberSum, uuid = null) {
            const that = this;
            if (numberSum) {
                this.currentNode = numberSum;
                this.dialogVisible = true;
            }
            if (uuid) {
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                getUpdateApartmentDetails(uuid).then(res => {
                    const {
                        info: {
                            floorNumber, ichnography, insideArea, outsideArea, parkingArea, propertyCode, roomNumber,
                            addresss
                        }
                    } = res;
                    that.uuid = uuid;
                    const oldFormHouse = that.formHouse;
                    that.formHouse = {
                        ...oldFormHouse, floorNumber, ichnography, insideArea, outsideArea, parkingArea, propertyCode,
                        roomNumber, addresss
                    }
                    that.dialogVisible = true;
                }).finally(() => loading.close());
            }
        },
        // 提交
        handleSubmitHouse() {
            const that = this;
            this.$refs['formHouse'].validate((valid) => {
                if (valid) {
                    const formData = that.formHouse;
                    const {
                        propertyCode, floorNumber, roomNumber, outsideArea, insideArea, parkingArea, apartmentType,
                        ichnography, addresss,
                    } = formData;
                    const data = {
                        propertyCode, floorNumber, roomNumber, outsideArea, insideArea, parkingArea, apartmentType,
                        ichnography, addresss
                    }

                    const uuid = that.uuid;
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    if (uuid) {
                        putHouseDetailApi(uuid, data).then((res) => {
                            const {info} = res;
                            MessageSuccess("修改房源成功");
                            that.handleCancel("update",info);
                        }).finally(() => loading.close());
                    } else {
                        const {type: buildType, uuid: buildUnitUuid} = that.currentNode;
                        data.buildType = buildType;
                        data.buildUnitUuid = buildUnitUuid;
                        addHouseApi(data).then(() => {
                            MessageSuccess("添加房源成功");
                            that.handleCancel("add");
                        }).finally(() => loading.close());
                    }

                }
            });
        },
        // 取消
        handleCancel(closeType = "cancel", data = null) {
            this.dialogVisible = false;
            this.formHouse = {
                propertyCode: null, floorNumber: null, roomNumber: null, outsideArea: null, insideArea: null,
                parkingArea: null, ichnography: null, addresss: null, apartmentType: "", //apartmentType: "三居室",
            };
            // 更新后，刷新tree数据，和当前节点房源数据
            closeType === "update" && this.$emit("handleSearch", data);

            closeType === "add" && this.$parent.getCommunityTreeData(this.currentNode);
        },

        handleUploadSuccess({info}) {
            // 上传图片
            this.formHouse.ichnography = info.uuid;
        },
    },
};
</script>

<style lang="scss" scoped>
.layer-add-house {
    .el-input-number,
    .el-select {
        width: 100%;
    }
}
</style>
