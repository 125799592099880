<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="handleSubmitCommunity"
                @click-cancel="handleCancel">
        <el-form ref="formCommunity" label-width="110px" size="small" :model="formCommunity" :rules="rules">
            <el-form-item label="小区名称" prop="name">
                <el-input  v-model="formCommunity.name" :disabled="addEdit==='edit'"></el-input>
            </el-form-item>
            <div class="house-manage flex justify-between">
                <el-form-item label="管家姓名" prop="houseManageName">
                    <el-select ref="houseManageNameRef" v-model="formCommunity.houseManageName" placeholder="请选择管家姓名"
                               @change="handleChange" clearable filterable>
                        <el-option v-for="item in users" :key="item.username" :label="item.fullname"
                                   :value="item.username"/>
                    </el-select>
                </el-form-item>
                <el-form-item label="管家手机号" prop="houseManagePhone">
                    <el-input v-model="formCommunity.houseManagePhone" placeholder="管家手机号码"/>
                </el-form-item>
            </div>
        </el-form>

        <!--     高德地图     -->
        <GaoDeMap class="gao-de-map" ref="gaoDeMapRef" show-autocomplete @on-autocomplete="handleAutocomplete"
                  :inputChange.sync="formCommunity.name"/>
    </r-e-dialog>
</template>

<script>
import GaoDeMap from "@/components/GaoDeMap";
import {formCommunityRules} from "@/views/rental-management/community-management/data";
import * as communityManagementApi from "@/api/community-management";
import {MessageSuccess, MessageWarning} from "@custom/message";
import {getUserListApi} from "@/api/publish";
import REDialog from "@/components/Gloabl/r-e-dialog/index.vue";

export default {
    name: "layer-add-edit-community",
    data() {
        return {
            dialogTitle: "新增小区",
            dialogVisible: false,
            formCommunity: {
                name: '',
                houseManageName: '',
                houseManagePhone: '',
                propertyCode: ''
            },
            users: [],
            rules: formCommunityRules(),
            addEdit: 'add'
        };
    },
    components: {REDialog, GaoDeMap},
    async created() {
        // 获取管理员
        let users = await getUserListApi();
        this.users = users.list;
    },
    methods: {

        //新建或编辑小区弹框打开方法
        layerAddEditCommunityInit(addEdit, row) {
            // 页面初始化，父页面传递数据
            this.addEdit = addEdit;
            this.dialogVisible = true;
            this.dialogTitle = addEdit === "add" ? "新增小区" : "编辑小区";
            if (addEdit === "edit") this.editCommunityInit(row);
        },

        //编辑小区数据回显
        editCommunityInit(row) {
            const oldData = this.formCommunity;
            // 编辑页面回显数据
            this.formCommunity = {...oldData, ...row};
        },

        //管家选择回调
        handleChange(e) {
            // 获取管家手机号
            const {mobile} = this.users.find(v => v.username === e);
            // 回显管家手机号
            this.formCommunity.houseManagePhone = mobile;
        },

        //小区搜索回调
        handleAutocomplete(data) {
            // 通过地图，选择小区，获取数据
            this.formCommunity.address = data.district + data.address
            this.formCommunity.longtitude = data.location.lng
            this.formCommunity.latitude = data.location.lat
        },

        //弹框确认
        handleSubmitCommunity() {
            // 表单校验
            this.$refs["formCommunity"].validate((valid) => {
                if (valid) {
                    //判断是添加还是修改
                    const request = this.addEdit === "add" ? "addCommunityApi" : "editCommunityApi";
                    //调用接口，添加或修改小区
                    communityManagementApi[request](this.formCommunity).then(() => {
                        MessageSuccess(`${this.dialogTitle}成功!`);
                        this.handleCancel("update");
                    });
                } else {
                    MessageWarning("请填写必填值", 1000);
                    return false;
                }
            });
        },
        // 取消、关闭弹窗
        handleCancel(closeType = "cancel") {
            // 取消、关闭弹窗
            this.dialogVisible = false;
            this.formCommunity = {};
            this.$refs["formCommunity"].resetFields();
            // 新增、编辑成功，需要刷新数据
            if (closeType === "update") this.$parent.handleSearch();
        },
      // pickerclick(e){
      //     // alert('9999')
      //   console.log(this.$refs['houseManageNameRef'])
      //   // this.$refs['houseManageNameRef'].blur();
      // },
    }
};
</script>

<style lang="scss" scoped>
.layer-add-edit-community {
    .gao-de-map {
        width: 100%;
        height: VH(400px);
    }

    .house-manage .el-form-item {
        width: 50%;

        .el-select {
            width: 100%;
        }
    }
}
</style>
